<template>
	<div class="floor-style-2">
		<div class="head-wrap" v-if="data.value.title.value.text">
			<h2 @click="$router.pushToTab(data.value.title.value.link.url)" :style="{ color: data.value.title.value.color }">{{ data.value.title.value.text }}</h2>
			<p @click="$router.pushToTab(data.value.subTitle.value.link.url)" :style="{ color: data.value.subTitle.value.color }">{{ data.value.subTitle.value.text }}</p>
		</div>
		<div class="body-wrap">
			<ul class="goods-list">
				<li v-for="(item, index) in data.value.goodsList.value.list" :key="index" :title="item.goods_name" @click="goSku(item.sku_id)">
					<div class="img-wrap"><img alt="商品图片" :src="$img(item.sku_image)" @error="imageError(index)" /></div>
					<h3>{{ item.goods_name }}</h3>
					<p class="desc">{{ item.introduction }}</p>
					<div class="price" v-if="item.unset_price==1">
						<span class="num" v-if="item.bottom_price>0||item.top_price>0">￥{{ item.bottom_price }}</span>
						<span class="num" v-else>面议</span>
						<!-- <span class="num">￥{{ item.discount_price }}</span> -->
						<!-- <del>{{ item.market_price }}元</del> -->
						<span class="btn">联系我们</span>
					</div>
					<div class="price" v-else>
						<span class="num" v-if="item.is_negotiable==1 && (item.bottom_price>0||item.top_price>0)">￥{{ item.bottom_price }}</span>
						<span class="num" v-else>￥{{ item.discount_price }}</span>
						<span class="btn">联系我们</span>
					</div>
				</li>
			</ul>
		</div>
		<div class="bottom-wrap" v-if="data.value.bottomImg.value.url">
			<img :src="$img(data.value.bottomImg.value.url)" @click="$router.pushToTab(data.value.bottomImg.value.link.url)" />
		</div>
	</div>
</template>

<script>
import { mapGetters } from 'vuex';
export default {
	name: 'floor-style-2',
	props: {
		data: {
			type: Object
		}
	},
	data() {
		return {};
	},
	created() {},
	computed: {
		...mapGetters(['defaultGoodsImage'])
	},
	methods: {
		goSku(skuId) {
			this.$router.pushToTab('/sku-' + skuId);
		},
		imageError(index) {
			this.data.value.goodsList.value.list[index].sku_image = this.defaultGoodsImage;
		}
	}
};
</script>

<style lang="scss">
.floor-style-2 {
	.head-wrap {
		text-align: center;
		h2 {
			line-height: 30px;
			color: #333;
			padding: 10px;
			font-size: 24px;
			cursor: pointer;
			overflow: hidden;
			text-overflow: ellipsis;
			white-space: nowrap;
		}
		p {
			color: #333333;
			padding: 0 10px;
			font-size: 18px;
			cursor: pointer;
			overflow: hidden;
			text-overflow: ellipsis;
			white-space: nowrap;
			margin-bottom: 20px;
		}
	}
	.body-wrap {
		.goods-list {
			display: flex;
			flex-wrap: wrap;
			li {
				width: 226px;
				margin-left: 15px;
				margin-bottom: 15px;
				background: #fff;
				cursor: pointer;
				padding: 10px 0;
				transition: all 0.2s linear;
				border: 1px solid #E5E5E5;
				&:nth-child(5n + 1) {
					margin-left: 0;
				}
				&:hover {
					z-index: 2;
					-webkit-box-shadow: 0 15px 30px rgba(0, 0, 0, 0.1);
					box-shadow: 0 15px 30px rgba(0, 0, 0, 0.1);
					-webkit-transform: translate3d(0, -2px, 0);
					transform: translate3d(0, -2px, 0);
				}
				.img-wrap {
					width: 188px;
					height: 188px;
					margin: 0 auto 15px;
					text-align: center;
					line-height: 188px;
					img {
						max-width: 100%;
						max-height: 100%;
					}
				}
				h3 {
					font-size: 14px;
					line-height: 18px;
					text-align: left;
					text-overflow: ellipsis;
					white-space: nowrap;
					overflow: hidden;
					margin: 0 15px;
				}
				.desc {
					margin: 0 12px 10px;
					height: 27px;
					font-size: 14px;
					color: #b0b0b0;
					text-align: left;
					text-overflow: ellipsis;
					white-space: nowrap;
					overflow: hidden;
				}
				.price {
					display: -webkit-flex;
					display:flex;
					flex-direction:row;
					flex-wrap: nowrap;
					justify-content:space-between;
					align-items: center;
					margin: 0 12px 0 12px;
					.num{
						text-align: left;
						font-size: 15px;
						color: $base-color;
						font-weight:bold;
					}
					.btn{width:66px;height:20px;line-height:20px;background-color:#FD6E20;border-radius:3px;font-size:12px;text-align:center;color:white;}
					del {
						margin-left: 0.5em;
						color: #b0b0b0;
					}
				}
			}
		}
	}
	.bottom-wrap {
		margin-top: 10px;
		width: $width;
		height: 118px;
		cursor: pointer;
		overflow: hidden;
		img {
			max-width: 100%;
		}
	}
}
</style>
